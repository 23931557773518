// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-jobs-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/templates/jobs.jsx" /* webpackChunkName: "component---src-templates-jobs-jsx" */),
  "component---src-templates-blog-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/templates/blog_post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-actualities-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/templates/actualities.jsx" /* webpackChunkName: "component---src-templates-actualities-jsx" */),
  "component---src-templates-actuality-post-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/templates/actuality_post.jsx" /* webpackChunkName: "component---src-templates-actuality-post-jsx" */),
  "component---src-pages-become-distributor-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/pages/become-distributor.jsx" /* webpackChunkName: "component---src-pages-become-distributor-jsx" */),
  "component---src-pages-contact-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mentionslegales-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/pages/mentionslegales.jsx" /* webpackChunkName: "component---src-pages-mentionslegales-jsx" */),
  "component---src-pages-solutions-agri-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/pages/solutions/agri.jsx" /* webpackChunkName: "component---src-pages-solutions-agri-jsx" */),
  "component---src-pages-solutions-cuma-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/pages/solutions/cuma.jsx" /* webpackChunkName: "component---src-pages-solutions-cuma-jsx" */),
  "component---src-pages-solutions-eta-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/pages/solutions/eta.jsx" /* webpackChunkName: "component---src-pages-solutions-eta-jsx" */),
  "component---src-pages-solutions-industriels-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/pages/solutions/industriels.jsx" /* webpackChunkName: "component---src-pages-solutions-industriels-jsx" */),
  "component---src-pages-solutions-lecompteur-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/pages/solutions/lecompteur.jsx" /* webpackChunkName: "component---src-pages-solutions-lecompteur-jsx" */),
  "component---src-pages-solutions-viti-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/pages/solutions/viti.jsx" /* webpackChunkName: "component---src-pages-solutions-viti-jsx" */),
  "component---src-pages-who-are-we-jsx": () => import("/Users/thomas/Dev/Freelance/karnott/src/pages/who-are-we.jsx" /* webpackChunkName: "component---src-pages-who-are-we-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/thomas/Dev/Freelance/karnott/.cache/data.json")

